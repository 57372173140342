<template>
  <dx-util-popup
    ref="tablePopupRef"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="tablePopupShown"
    @hidden="tablePopupHidden"
  >
    <dx-util-scroll-view width="100%" height="100%">
      <div class="row">
        <div class="col-6">
          <div>
            <div class="d-flex justify-content-between">
              <div>
                <span class="text-muted">
                  Store:
                </span>
                <span class="text-uppercase">
                  {{ tableData.storeName }}
                </span>
              </div>
              <div>
                <span class="text-muted">
                  Period:
                </span>
                <span>
                  {{ tableData.month }} / {{ tableData.year }}
                </span>
              </div>
            </div>
            <div>
              <span class="text-muted">
                Company:
              </span>
              <span>
                {{ tableData.companyName }}
              </span>
            </div>
            <table>
              <tbody>
                <tr>
                  <th>Title</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>Gross Sales</td>
                  <td>{{ formatCurrency(tableData.sales) }}</td>
                </tr>
                <tr>
                  <td>Total After Amazon Fees</td>
                  <td>{{ formatCurrency(tableData.total) }}</td>
                </tr>
                <tr>
                  <td>Item Quantity Sold</td>
                  <td>{{ formatNumber(tableData.quantity) }}</td>
                </tr>
                <tr>
                  <td>Cost Of Goods Sold</td>
                  <td>{{ formatCurrency(tableData.cogs) }}</td>
                </tr>
                <tr>
                  <td>Profit</td>
                  <td>{{ formatCurrency(tableData.profit) }}</td>
                </tr>
                <tr>
                  <td>Refunds</td>
                  <td>{{ formatCurrency(tableData.refunds) }}</td>
                </tr>
                <tr>
                  <td>Amazon Fees</td>
                  <td>{{ calculateAmazonFees() }}</td>
                </tr>
                <tr>
                    <td colspan="2" class="sub-table">
                      <table>
                        <thead class="text-muted">
Amazon Fee Details
</thead>
                        <tbody>
                          <tr>
                            <td>FBA Fees</td>
                            <td>{{ formatCurrency(tableData.sellingFee) }}</td>
                          </tr>
                          <tr>
                            <td>FBA Fees</td>
                            <td>{{ formatCurrency(tableData.fbaFee) }}</td>
                          </tr>
                          <tr>
                            <td>Amazon Shipping Fees</td>
                            <td>{{ formatCurrency(tableData.amazonShippingFees) }}</td>
                          </tr>
                          <tr>
                            <td>FBA Inventory Fee Return</td>
                            <td>{{ formatCurrency(tableData.fbaInventoryFeeReturn) }}</td>
                          </tr>
                          <tr>
                            <td>FBA Inventory Fee Shipment</td>
                            <td>{{ formatCurrency(tableData.fbaInventoryFeeShipment) }}</td>
                          </tr>
                          <tr>
                            <td>FBA Inventory Fee Storage</td>
                            <td>{{ formatCurrency(tableData.fbaInventoryFeeStorage) }}</td>
                          </tr>
                          <tr>
                            <td>FBA Inventory Fee Storage Long</td>
                            <td>{{ formatCurrency(tableData.fbaInventoryFeeStorageLong) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                <tr>
                  <td>Warehouse Service Fees</td>
                  <td>{{ formatCurrency(tableData.warehouseServiceFees) }}</td>
                </tr>
                <tr>
                  <td>Warehouse Shipping Fees</td>
                  <td>{{ formatCurrency(tableData.warehouseShippingFees) }}</td>
                </tr>
                <tr>
                  <td>Unmatched Item Qty</td>
                  <td>{{ formatNumber(tableData.unmatchedQty) }}</td>
                </tr>
                <tr>
                  <td>Unmatched Sales</td>
                  <td>{{ formatCurrency(tableData.unmatchedSales) }}</td>
                </tr>
                <tr>
                  <td>Adjustments</td>
                  <td>{{ formatCurrency(tableData.adjustments) }}</td>
                </tr>
                <tr>
                  <td>Sales Tax</td>
                  <td>{{ formatCurrency(tableData.salesTx) }}</td>
                </tr>
                <tr>
                  <td>Shipping Credits</td>
                  <td>{{ formatCurrency(tableData.shipCred) }}</td>
                </tr>
                <tr>
                  <td>Gift Credits</td>
                  <td>{{ formatCurrency(tableData.giftCred) }}</td>
                </tr>
                <tr>
                  <td>Promotion Rebates</td>
                  <td>{{ formatCurrency(tableData.promRebates) }}</td>
                </tr>
                <tr>
                  <td>Withdrawals</td>
                  <td>{{ formatCurrency(tableData.withdrawals) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-6 d-flex justify-content-center p-3">
          <DxPieChart
            id="pie"
            :data-source="chartData"
            type="doughnut"
            title="Detailed Snapshot"
            palette="Material"
            @point-click="pointClickHandler($event)"
            @legend-click="legendClickHandler($event)"
          >
            <DxSize :width="800" :height="800" />
            <DxSeries value-field="value" argument-field="type">
              <DxLabel
                :visible="true"
                format="thousands"
              >
                <DxConnector :visible="true" />
              </DxLabel>
            </DxSeries>
            <DxLegend
              :margin="0"
              horizontal-alignment="center"
              vertical-alignment="bottom"
            >
            <DxLabel
              :customize-text="customizeLegendText"
            />
            </DxLegend>
          </DxPieChart>
        </div>
      </div>
    </dx-util-scroll-view>
  </dx-util-popup>
</template>

<script>
import { currencyFormatter } from '@core/utils/filter'
import DxPieChart, {
  DxLegend,
  DxSeries,
  DxLabel,
  DxConnector,
  DxSize,
} from 'devextreme-vue/pie-chart'

export default {
  components: {
    DxSeries,
    DxLabel,
    DxConnector,
    DxPieChart,
    DxLegend,
    DxSize,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    tableData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
    }
  },
  computed: {
    tablePopup() {
      return this.$refs.tablePopupRef.instance
    },
    chartData() {
      const propertiesToExtract = [
        { key: 'type', value: 'cogs', label: 'COGS' },
        { key: 'type', value: 'fbaFee', label: 'FBA Fees' },
        { key: 'type', value: 'sellingFee', label: 'Selling Fees' },
        { key: 'type', value: 'amazonShippingFees', label: 'Amazon Shipping Fees' },
        { key: 'type', value: 'fbaInventoryFee', label: 'FBA Inventory Fees' },
        { key: 'type', value: 'refunds', label: 'Refunds' },
        { key: 'type', value: 'profit', label: 'Profit' },
        { key: 'type', value: 'warehouseFees', label: 'Warehouse Fees' },
        // adjust for the needs
      ]

      // Convert the object to an array of objects
      const convertedData = propertiesToExtract.map(property => ({
        [property.key]: property.label,
        value: Math.abs(this.tableData[property.value]),
      }))

      return convertedData
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.tablePopup.show()
      },
    },
  },
  methods: {
    tablePopupShown() {
      this.popupTitle = 'More Information On Monthly Sales'
    },
    tablePopupHidden() {
    },
    closePopup() {
      this.tablePopup.hide()
    },
    calculateAmazonFees() {
      return this.formatCurrency(this.tableData.sellingFee + this.tableData.fbaFee + this.tableData.fbaInventoryFee + this.tableData.amazonShippingFees)
    },
    formatCurrency(v) {
      return currencyFormatter(v)
    },
    formatNumber(number) {
      if (typeof number !== 'number' || Number.isNaN(number)) {
        return 'N/A'
      }
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 })
    },
    customizeLegendText({ text }) {
      return text.toUpperCase()
    },
    pointClickHandler(e) {
      this.toggleVisibility(e.target)
    },
    legendClickHandler(e) {
      const arg = e.target
      const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0]

      this.toggleVisibility(item)
    },
    toggleVisibility(item) {
      // eslint-disable-next-line no-unused-expressions
      item.isVisible() ? item.hide() : item.show()
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  margin-top: 24px;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

th {
  background-color: rgb(98, 111, 123);
  border: 1px solid rgb(70, 138, 206, 0.5);
  color: white;
  padding: 8px;
  text-align: left;
}

td {
  border: 1px solid rgb(70, 138, 206, 0.5);
  padding: 8px;
}

tr:nth-child(even) {
  background-color: rgba(118, 140, 218, 0.1);
}

.sub-table {
  padding-left: 20px;
  padding-top: 0px;
}
</style>
